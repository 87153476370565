<script setup>
import ModalWrapper from '@/modals/ModalWrapper';
import { ref, onMounted } from 'vue';
import { useCertificateStore } from '@/stores/certificate';
import { formatAmount } from '@/utils/formatHelper';

const certificateStore = useCertificateStore();
const { getCustomerName, getFsmName } = certificateStore;

const props = defineProps({
    availableCertificates: {
        type: Array,
        default: () => []
    },
    selectedCertificates: {
        type: Array,
        default: () => []
    },
    updateCertificates: {
        type: Function,
        default: () => {}
    }
});

const allCertificates = ref([]);

const selectedCertificateIds = ref(new Set());

const all = ref({
    data: [],
    total: 0,
    per_page: 100
});

const initializeSelection = () => {

    selectedCertificateIds.value.clear();

    // Make sure we have the available certificates
    allCertificates.value = [...props.availableCertificates];

    // Initialize selections from props
    if (Array.isArray(props.selectedCertificates)) {
        props.selectedCertificates.forEach(cert => {
            if (cert && cert.id) {
                selectedCertificateIds.value.add(cert.id);
            }
        });
    }

    all.value = {
        data: allCertificates.value,
        total: allCertificates.value.length,
        per_page: 100
    };
};

const onSelected = (certificate, selected) => {

    if (selected) {
        selectedCertificateIds.value.add(certificate.id);
    } else {
        selectedCertificateIds.value.delete(certificate.id);
    }

    // Call the parent component's update function
    props.updateCertificates(certificate, selected);

};

const isCertificateSelected = (certificate) => {
    return selectedCertificateIds.value.has(certificate.id);
};

const tableUpdate = () => {
};

onMounted(() => {
    initializeSelection();
});

defineExpose({
    initializeSelection
});
</script>

<template>
<ModalWrapper>
    <template #header>Refunded Certificates</template>
    <DataTable
        :data="all"
        :headers="[
            { title: $t('label.select'), key: 'select', sortable: false },
            { title: $t('label.cert_no'), key: 'certificate_number' },
            { title: $t('label.customer_name'), key: 'customer_name', value: item => getCustomerName(item) },
            { title: $t('label.remittance_total'), key: 'remittance_total', value: item => formatAmount(item.remittance_total) },
            { title: $t('label.dealership'), key: 'dealership_id', value: item => item.dealership.name },
            { title: $t('label.fsm'), key: 'fsm', value: item => getFsmName(item) },
            { title: $t('label.status'), key: 'global_status_id', value: item => item.global_status.name },
            { title: $t('label.purchase_date'), key: 'purchase_date' },
        ]"
        @table-update="tableUpdate">
        <template #[`item.select`]="{ item }">
            <div class="flex items-center">
                <StyledCheckbox
                    type="checkbox"
                    :model-value="isCertificateSelected(item)"
                    class="hide-details"
                    @update:model-value="(selected) => onSelected(item, selected)" />
            </div>
        </template>
    </DataTable>
</ModalWrapper>
</template>
