<script setup>
import { ref, computed, inject, onMounted, watch } from 'vue';
import * as navConfig from '@/navConfig';
import { router } from '@inertiajs/vue3';
import { useDisplay } from 'vuetify';
import { useUserStore } from '@/stores/user';
import { useOptionsStore } from '@/stores/options';
import { storeToRefs } from 'pinia';
import { isRole } from '@/utils/roleHelper';
import { loadLanguageAsync } from 'laravel-vue-i18n';

defineOptions({
    inheritAttrs: false
});

const drawer = ref(true);
const rail = ref(false);
const { thresholds } = useDisplay();

const { profile, lastLogin, locale } = storeToRefs(useUserStore());
const { securityCheckpoint } = storeToRefs(useOptionsStore());
const roles = computed(() => isRole(profile.value));

const isLoading = inject('isLoading');

const routes = computed(() => {
    let allRoutes = [];

    if (roles.value.superAdmin()) {
        allRoutes.push(...navConfig.superAdmin());
    }

    if (roles.value.admin()) {
        allRoutes.push(...navConfig.admin());
    }

    if (roles.value.dealershipManager()) {
        allRoutes.push(...navConfig.dealershipManager());
    }

    if (roles.value.finance()) {
        allRoutes.push(...navConfig.finance());
    }

    if (roles.value.fsm()) {
        allRoutes.push(...navConfig.fsm());
    }

    const uniqueRoutes = allRoutes.reduce((acc, current) => {
        const x = acc.find(item => item.name === current.name);
        return x ? acc : [...acc, current];
    }, []);

    return uniqueRoutes;
});

const bottomNavRoutes = computed(() => {
    if (roles.value.superAdmin()) {
        return navConfig.bottomNavRoutes();
    } else {
        return navConfig.bottomNavRoutes().filter(x => !x.private);
    }
});

const isLocal = computed(() => {
    return import.meta.env.VITE_ENV === 'local';
});

onMounted(() => {
    if (window.innerWidth <= thresholds.value.md) {
        drawer.value = false;
    }
});

const logout = async () => {
    await router.post('/logout');
};

const changeBar = () => {
    if (window.innerWidth <= thresholds.value.md) {
        rail.value = false;
    }
    drawer.value = !drawer.value;
};

const activeRoute = ref(null);

const visitPath = (route) => {
    activeRoute.value = route.path;
    if (route.private) {
        window.open(route.path, '_blank');
    } else {
        router.visit(route.path);
    }
    if (window.innerWidth <= thresholds.value.md) {
        drawer.value = false;
    }
};

const { setLocale } = useUserStore();
watch(locale, (lang) => {
    loadLanguageAsync(lang);
});

</script>

<template>
<div>
    <v-app>
        <v-navigation-drawer
            v-if="!securityCheckpoint"
            v-model="drawer"
            :rail="rail"
            mobile-breakpoint="md"
            app>
            <v-list>
                <img
                    :src="rail ? '/images/icon-logo.png' : '/images/logo.png'"
                    :class="rail ? 'mx-auto px-2 pt-1.5' : 'w-full px-4 object-contain h-14'"
                    alt="Logo"
                    class="cursor-pointer"
                    @click="$inertia.visit('/')">
            </v-list>

            <v-list class="nav">
                <div class="hidden md:flex" :class="!rail ? 'justify-end' : 'justify-center'">
                    <v-app-bar-nav-icon
                        v-tooltip.right="rail ? 'Open' : 'Minimize'"
                        color="accent"
                        :icon="!rail ? 'mdi-arrow-collapse-left' : 'mdi-arrow-collapse-right'"
                        @click="rail = !rail" />
                </div>
                <v-list-item
                    v-for="route in routes"
                    :key="route.name"
                    v-tooltip.right="!drawer ? '' : route.label"
                    :class="{'v-list-item--active': route.name === $page.component}"
                    :variant="route.name === $page.component ? 'tonal' : 'text'"
                    :title="route.label"
                    :value="route.name"
                    @click="visitPath(route)">
                    <template #prepend>
                        <v-progress-circular
                            v-if="isLoading && route.path === activeRoute"
                            :width="3"
                            :size="24"
                            class="mr-8"
                            color="secondary"
                            indeterminate />
                        <v-icon v-else-if="activeRoute === route.path" :icon="route.icon" color="secondary" />
                        <v-icon v-else :icon="route.icon" />
                    </template>
                </v-list-item>
            </v-list>

            <template v-if="!securityCheckpoint" #append>
                <div class="border-t border-grey-500">
                    <v-list>
                        <v-list-item
                            v-for="route in bottomNavRoutes"
                            :key="route.name"
                            v-tooltip.right="!drawer ? '' : route.label"
                            :class="{'v-list-item--active': route.name === $page.component}"
                            :variant="route.name === $page.component ? 'tonal' : 'text'"
                            :title="route.label"
                            :value="route.name"
                            @click="visitPath(route)">
                            <template #prepend>
                                <v-progress-circular
                                    v-if="isLoading && route.path === activeRoute"
                                    :width="3"
                                    :size="24"
                                    class="mr-8"
                                    color="secondary"
                                    indeterminate />
                                <v-icon v-else-if="activeRoute === route.path" :icon="route.icon" color="secondary" />
                                <v-icon v-else :icon="route.icon" />
                            </template>
                        </v-list-item>

                        <v-list-item
                            v-if="isLocal"
                            v-tooltip.right="!drawer ? '' : 'Project Setup'"
                            prepend-icon="mdi-magnify"
                            title="Project Setup"
                            @click.prevent="$inertia.visit('/local/project')">
                            <template #prepend>
                                <v-progress-circular
                                    v-if="isLoading && activeRoute === '/local/project'"
                                    :width="3"
                                    :size="24"
                                    class="mr-8"
                                    color="secondary"
                                    indeterminate />
                                <v-icon v-else-if="$page.component === 'Local/ProjectSetup'" icon="mdi-magnify" color="secondary" />
                                <v-icon v-else icon="mdi-magnify" />
                            </template>
                        </v-list-item>
                        <v-list-item
                            v-tooltip.right="!drawer ? '' : 'Sign Out'"
                            prepend-icon="mdi-power-standby"
                            title="Sign Out"
                            value="logout"
                            @click.prevent="logout">
                            <template #prepend>
                                <v-icon icon="mdi-power-standby" />
                            </template>
                        </v-list-item>
                    </v-list>
                </div>
            </template>
        </v-navigation-drawer>

        <v-app-bar v-if="!securityCheckpoint" app>
            <div class="block md:hidden ml-4">
                <v-app-bar-nav-icon @click="changeBar" />
            </div>
            <div class="flex justify-items-end justify-end w-full mr-6">
                <img
                    class="md:hidden block h-12 object-scale-down"
                    src="/images/icon-logo.png"
                    alt="Logo">
            </div>
        </v-app-bar>

        <v-main app>
            <v-container fluid>
                <slot />
            </v-container>
        </v-main>

        <div class="z-[1008]">
            <modals-container />
        </div>

        <v-footer app>
            <div class="mb-0 flex justify-between w-full items-center mx-4 text-sm">
                <div class="italic">Last login: {{ lastLogin }}</div>
                <div>Copyright &copy; Landsperg Automotive Group {{ new Date().getFullYear() }}</div>
                <v-btn-toggle
                    :model-value="locale"
                    class="!h-6 min-w-20 ml-2"
                    variant="outlined"
                    color="primary"
                    mandatory
                    group
                    @update:model-value="setLocale">
                    <v-btn size="x-small" value="en">
                        En
                    </v-btn>
                    <v-btn size="x-small" value="fr">
                        Fr
                    </v-btn>
                </v-btn-toggle>
            </div>
        </v-footer>
    </v-app>
</div>
</template>
